import React from 'react'

import logoBepass from '../assets/images/logo-bepass.png'

import clubLogo from '../images/club_logo.png'

export function SideBar() {
  const currentUser = sessionStorage.getItem('role')

  return (
    <nav className="pcoded-navbar menu-dark navbar-default brand-default drp-icon-style1 menu-item-icon-style1 active-default title-default">
      <div className="navbar-wrapper">
        <div className="text-center">
          <a className="" href="/dashboard">
            <img
              style={{ width: '60%', marginTop: '20px' }}
              src={clubLogo}
              alt="activity-user"
            />
          </a>
          {/* <a className="mobile-menu" id="mobile-collapse" href="/dashboard">
            <span></span>
          </a> */}
        </div>
        <div className="navbar-content datta-scroll">
          <div className="scrollbar-container ps">
            <ul id="nav-ps-next" className="nav pcoded-inner-navbar    -flush">
              <li className="nav-item pcoded-menu-caption  ">
                <label>Acessos</label>
              </li>
              <li className=" ">
                <a className="nav-link" target="" href="/dashboard">
                  <span className="pcoded-micon">
                    <i className="feather icon-home"></i>
                  </span>
                  <span className="pcoded-mtext">Inicio</span>
                </a>
              </li>
              <li className=" ">
                <a className="nav-link" target="" href="/passports">
                  <span className="pcoded-micon">
                    <i className="feather icon-file-plus"></i>
                  </span>
                  <span className="pcoded-mtext">Passaportes</span>
                </a>
              </li>
            </ul>
            {(currentUser === 'bedimin') && (
              <ul className="nav pcoded-inner-navbar    -flush">
              <li className=" ">
                  <a className="nav-link" target="" href="/game/distribution">
                    <span className="pcoded-micon">
                      <i className="feather icon-server"></i>
                    </span>
                    <span className="pcoded-mtext">Organizar partida</span>
                  </a>
                </li>
                <li className=" ">
                  <a className="nav-link" target="" href="/game/tickets">
                    <span className="pcoded-micon">
                      <i className="feather icon-refresh-cw"></i>
                    </span>
                    <span className="pcoded-mtext">Tickets Sync</span>
                  </a>
                </li>
                <li className=" ">
                  <a className="nav-link" target="" href="/terminals">
                    <span className="pcoded-micon">
                      <i className="feather icon-cpu"></i>
                    </span>
                    <span className="pcoded-mtext">Catracas</span>
                  </a>
                </li>
              </ul>
                )}
              {(currentUser === 'admin' || currentUser === 'bedimin') && (
              <ul className="nav pcoded-inner-navbar    -flush">
              <li className=" ">
                  <a className="nav-link" target="" href="/user/create">
                    <span className="pcoded-micon">
                      <i className="feather icon-server"></i>
                    </span>
                    <span className="pcoded-mtext">Cadastrar Usuários</span>
                  </a>
                </li>
              </ul>
                )}

            <ul id="nav-ps-next" className="nav pcoded-inner-navbar    -flush">
              <li className="nav-item pcoded-menu-caption  ">
                <label></label>
              </li>
              <li className=" ">
                <a className="nav-link" target="" href="/user/edit/me">
                  <span className="pcoded-micon">
                    <i className="feather icon-user"></i>
                  </span>
                  <span className="pcoded-mtext">Meus dados</span>
                </a>
              </li>

              <li className="disabled">
                <a
                  className="nav-link"
                  target=""
                  href="/"
                  onClick={() => {
                    sessionStorage.removeItem('auth')
                  }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-power"></i>
                  </span>
                  <span className="pcoded-mtext">Sair</span>
                </a>
              </li>
            </ul>
            <div className="ps__rail-x">
              <div className="ps__thumb-x"></div>
            </div>
            <div className="ps__rail-y">
              <div className="ps__thumb-y"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
