import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
    BsEmojiSunglasses,
    BsSun,
    BsPeople,
    BsPersonBoundingBox,
    BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { GuestCreate } from '../components/guestCreate'
import { FindGameUser } from '../components/findGameUser'
import { TicketCount } from '../components/ticketCount'
import { PassportCount } from '../components/passportCount'

import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveFunnel } from '@nivo/funnel'


function DashAvanti() {
    const role = sessionStorage.getItem('role')
    const auth = sessionStorage.getItem('auth')

    if (auth !== 'true') {
        window.location.href = '/'
    }
    const [photoState, setphotoState] = useState<string>('')

    const handleResponse = useCallback((message: string) => {
        setphotoState(message)
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        if (params.get('fixBrowser')) {
            setphotoState('mobile')
        }
    }, [])

    return (
        <>
        <Row>
          
                <Col lg={8} xl={6}>
                    {/* <FindUser /> */}
                    <FindGameUser />
                </Col>

                { role !== 'queryProfile' && (
                    <Col lg={8} xl={6}>
                        {<PassportCount />}
                        {/* {<TicketCount />} */}
                    </Col>
                )}
            </Row>
        </>
    )
}

export default DashAvanti
